.header-main {
  height:55px;
  vertical-align: middle;
  line-height: 50px;
  padding-left:25px;
}
.App-header{
  box-shadow:0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
}
.assurant {
  padding-right: 25px;
  text-transform: uppercase;
  /* border-right: 0.5px solid rgba(20, 20, 20, 0.3); */
  display: inline-flex;
  vertical-align: middle;
}
.global-automotive {
  padding-left: 20px;
  height: 100%;
  vertical-align: top;
  font-size: 18px;
  letter-spacing: 1.5px;
  font-weight: 700;
  color: #666666;
}

.logout {
  float: right;
  padding-right:20px;
}

.system-status {
  padding-top: 5px;
  line-height: 2px;
  margin: 5px 0 0 0;
}
.system-main-status{
   line-height: 2;
  margin: 5px 0 0;
}

.update-status {
  font-size: 14px;
  padding-top: 10px;
  /* line-height: 11px; */
  color: #68758B;
  line-height: 1;
  margin: 0px;
}

.day-style {
  font-size: 32px;
  font-weight: 700;
  color: #13223C;
}
.tile-month{
  font-size: 16px;
  color: #13223C;
  font-weight: 400;
}

.footer {
    color: white;
    margin-top: 1rem;
    background-color: #303340;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 6px;
    
}
.FooterContent{
  width:97%;
  font-size: 14px;
  padding-bottom: 10px;
}


.admin-footer {
  color: white;
  padding-left: 30%;
}

.need-help {
  float: right;
  padding-left: 20px;
  text-decoration: underline;
}
.need-help a{
  color:#fff;
}

.admin-button {
  float: right;
  /* padding-right: 20px; */
  text-decoration: underline;
  cursor: pointer;
}
.blackBG{
  background-color: black !important;
  color:white !important;
}
.whiteColor{
  color:white !important;
}
.blackColor{
  color:black !important;
  
}
.user-button{
  border-radius: 20px !important;
  float: right;
  width: 100px !important;
  font-size: 12px !important;
  line-height: 0px;
  border: 0.5px solid rgba(20, 20, 20, 0.3);
  box-sizing: border-box;
  height: 33px;
  margin-top: 10px;
  margin-right: 10px;
}
.userbutton{
  border-radius: 20px !important;
  float: right !important;
  width: 100px !important;
  font-size: 12px !important;
  padding-left: 10px;
  border: 0.5px solid rgba(20, 20, 20, 0.3);
  box-sizing: border-box;
  height: 33px;
  margin-top: 10px;
  margin-right: 10px;
}

.admin-btn {
  border-radius: 20px !important;
  width: 130px !important;
  font-size: 16px !important;
  padding-bottom: 10px;
  height: 30px;  
}

.user-button{
  border-radius: 20px !important;
  float: right;
  width: 130px !important;
  font-size: 16px !important;
  /*padding-left: 10px;*/
  border: 0.5px solid rgba(20, 20, 20, 0.3);
  box-sizing: border-box;
  height: 30px;
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

.logo-image {
  cursor: pointer;
  width: 100%;
  height: 35px;
}

.click-cursor{
  cursor: pointer;
}

.logout-icon {
  margin: 10px 20px 10px 10px;
  vertical-align: middle;
}
.admin-button .MuiTooltip-arrow::before{
  background-color:#303340;
  top: 7px;
  
}
.admin-button .MuiTooltip-tooltipArrow{
   background-color:#303340;
   border-radius: 4px !important;
   padding: 8px 16px !important;
   top: -2px !important;
   left:6px !important;
   font-family: 'Open Sans' !important;
    font-size: 14px;
   
 }


 

.right-logo{
  float: right;
  padding-left:10px;
}
.left-logo{
  float: right;
}
.remove-padding{
  padding-right:0px !important;
}
.padding-left{
  padding-left:20px;
}

.filter-icon {
  padding-right: 10px;
  vertical-align: middle;
}
#alert-dialog-title {
  display: none;
}
.filter {
  font-size: 20px;
  font-weight: normal;
  margin: 0.5em 1em 0.5em 0em;
}
#okta-sign-in .okta-form-input-field input {
  width: 90%!important;
}
.pageContent{
  height: calc(100vh - 120px);
  overflow: auto;
}
.MuiButton-root.Mui-disabled {
  color: #666666 !important;
  background-color: #F1F1F1 !important;
  border: 0.5px solid #1414144D;
  cursor: default;
  pointer-events: inherit !important;
}
.MuiButton-root{
  text-transform: capitalize !important;
}
.MuiButton-text{
  font-size: 14px !important;
}
.MuiTableCell-root {
  font-family: 'Open Sans' !important;
}
