.vehicle-listing-container {
  padding-left: 6%;
}

.vehicle-listing-container .container-grid-item {
  width: 100%;
  margin: 5px 0px !important;
}

.vehicle-listing-container .grid-item {
  width: 100%;
  padding: 5px !important;
}

.vehicle-listing-container .filter-ddl {
  /*width: 160px;*/
  width:100%;
}

.vehicle-listing-container .filter-ddl-last {
  width: 205px;
  left: 40px;
}
.blankDiv{
  width: 20%;
}
.pagination {
  display: inline-flex;
  margin: 7px 8px 10px 7px;
}

.pagination .show-pagecount {
  line-height: 0;
  padding-right: 10px;
}