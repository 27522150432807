.login {
  position: relative;
  background: #0066CC;
  overflow: auto;
  border: 1px solid #707070;
  height: 99.7vh;
}

.okta-form-title {
  font-size: 26px !important;
  text-transform: uppercase !important;
  margin-bottom: 1em !important;
}

.okta-form-input-field {
  border-radius: 40px !important;

  input {
    width: 90% !important;
  }
}

#okta-sign-in .focused-input{
  box-shadow: none !important;
}

#okta-sign-in .auth-header {
  border-bottom: 0 !important;
}

.okta-form-label,
.o-form-input-name-remember,
.o-form-has-errors {
  text-align: left;
}

.o-form-fieldset-container {
  text-align: center;
}

.button.button-primary {
  border-radius: 40px !important;
  width: 180px !important;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  font-weight: bold !important;
  text-align: center;
  border: 2px solid transparent !important;
  padding: 5px 0.75rem !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  height: auto !important;
}

.okta-widget {
  margin-top: 50px;
}