.login-main {
  background-color: #0066cc;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-screen {
  background-color: white;
  width: 400px;
  height: 600px;
  border-radius: 4px;
}

.title-name {
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  line-height: 44px;
}

.client-title {
  text-align: center;
  font-family: open sans;
  font-style: normal;
  line-height: 28px;
  size: 16px;
}
.tab-name {
  text-transform: capitalize !important;
}

.remember-me {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sign-in {
  line-height: 10px !important;
  text-transform: lowercase !important;
  width: 100px !important;
  border-radius: 20px !important;
}
.lower-border {
  width: 50%;
  border-bottom: 1px solid gray;
}

.assurant-logo {
  padding-top: 10px;
  display: block;
  margin: 0 auto;
}

.logo-icon {
  display: flex;
}

.signin-password {
  margin-top: 20px;
  padding-right: 10px;
}
