.tile-style {
  margin-top: 1%;
  display:inline-flex;
  width: 100%;
}
.card-alert {
  width:24%;
  /*width:290px;
  padding: 20px;*/
  margin: 10px;
  border: 1px solid #d5d5d5;
  box-shadow: 0 3px 0 0 rgb(0 0 0 / 20%);
  border-radius: 8px;
}

.tile-main {
  display: flex;
  flex-wrap: wrap;
}
.tile-left{
  width:10%;
}

.tile-right {
  padding-left: 20px;
}
.tile-event{
  width:50%;
}

.tile-middle {
  line-height: 1;
}

.tile-description {
  line-height: 1.2;
  font-size: 12px;
  border-bottom: 2px solid grey;
}

.tile-bottom {
  display: flex;
}

.tile-bottom-title {
  font-size: 14px;
  color: #68758B;
  font-weight: 400;
}
.tile-time{
  color: #3D515A;
  font-size: 14px;
}
.tile-status{
  color: #68758B;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
}
.tile-outageType{
  color: #3D515A;
  line-height: 1;
  font-size: 16px;
}

.tile-padding5 {
  padding-bottom: 5px;
}

.tile-bottom-right {
  margin-left: auto;
  padding-left: 10px;
}
.impact-system-name {
  font-size: 10px;
}
.system-name {
  padding-right: 5px;
  padding-left: 5px;
}

.system-last {
  padding-left: 5px;
}

.system-main {  
  display: inline-flex; 
  width:100%;
}

.filter-text {
  float: right;
  padding-right: 10px;
  margin: 0px 5px;
}
.filterSpan {
  color: grey;
  font-size: 14px;
}

.filter-dialog {
  width: 400px;
}

.system-affected {
  padding-right: 10px;
  color: blue;
  font-size: 12px;
}

.system-desc-title {
  color: gray;
}

.system-name {
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 5px;
}

.system-desc{
  font-size: 12px;
  color: gray;
  height: 60px;
}

.close-impact{
  float: right;
  cursor: pointer;
  width: 15%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.ouatge-date-picker {
  width: 100% !important;
}

.no-event-found {
  text-align: center;
}

.pagination {
  /* padding-top: 10px; */
  float: right;
  padding-right: 10px;
}

.outage-text {
  width: 330px !important;
  height: auto;
  -ms-word-break: break-all;
  word-break: break-all;
}

.outage-details-title {
  color: black;
  font-size: 20px;
}

.cancel-info {
    margin-top: 10px !important;
    margin-left: 40px !important;
    margin-right: 10px !important;
    color: white !important;
    width: 250px !important;
    border-radius: 20px !important;
    background-color: black !important;
}

.white-color {
  padding: 10px;
  background-color: white;
  border: black 1px;
  border-radius: 8px;
  /*box-shadow: 0px 3px 14px rgba(100, 128, 200, 0.1);*/
}

.grey-color {
  /*padding: 20px !important;*/
  padding:10px 20px 20px 20px !important;
  background-color: #f2f2f2;
  border: black 1px;
  border-radius: 8px;
  padding: 10px;
  /*box-shadow: 0px 3px 14px rgba(100, 128, 200, 0.1);*/
}
.filter-apply {
  border-radius: 18px !important;
  background-color: black !important;
  color: white !important;
  width: 105px !important;
  /*font-size: 12px !important;
  padding-bottom: 10px !important; */
  margin-top:20px !important;
  float:right;
}
.filter-cancel{ 

  border: 0.5px solid rgba(20, 20, 20, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 18px !important;
  width: 105px !important;
  height: 35px !important;
  /*font-size: 12px !important;
  padding-bottom: 10px !important; */
  margin-top:20px !important;  
}
.info{
  width: 20%;
  text-align: right;
}
.outage-type {
  margin-top: 20px;
}
.tile-system-impact{
  color:#68758B;
}

.scheduled-system {  
  font-size: 18px;
  font-weight:600;
  color: #13223C;
  margin-left: 12px;
  display: inline-flex;
}