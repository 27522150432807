.system-green {
    padding-top: 6px;
    background-color: green;
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    width: 60px;
    margin-right: 10px;
    height: 40px;
    margin-top: 8px;
}

.system-green-icon {
    float: right !important;
    margin-right: 8px;
    height: 30px;
}