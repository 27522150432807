.event-button {
    float: right;
    margin: 8px 6px 8px !important;
    border-radius: 25px !important;
    background-color: black !important;
    color: white !important;
    width: 130px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
}
.MuiTableCell-stickyHeader{
    border-bottom:0px;
}
.MuiTableCell-head {
    color: #13223C;
}
.MuiTableCell-body {
    color: #3d515a !important;
    padding: 6px !important;
    font-size: 14px !important;
}
.MuiTable-stickyHeader {
    padding: 10px 20px;
}
MuiTableCell-root {
    padding: 10px;
}
.makeStyles-container-2 {
    height: 462px;
}
.statusModal{
    width:150px !important;
    padding: 20px !important;
}
.admin-image{
    margin-right: 10px;
    height:18px;
}
.user-image{
    margin-right: 5px;
    height:15px;
}
.statusTick{
    margin-left: 25px;
}
.parentFilterEvent{
    display: block;
}
.btnAddEvent{
    float: right;
    width: 100%;
}
.filterCriteria{
    display: flex;
    float: right;
}
.arrow-style{
    padding-left:5px;
}
.parentFilterEvent .jss2 {
   height: calc(100vh - 280px);
}
.event-checkbox .MuiRadio-colorSecondary.Mui-checked {
    color: #0066CC;
}
.menuOption{
    height:35px;
}
.admin-reset-icon{
    margin-top: 3px;
    margin-bottom: 0px;
    font-size: 14px;
}