.event-dailog {
  width: 350px;
  cursor: pointer;
}
.event-drop {
  width: 100%;
}

.row-space {
  padding-bottom: 15px;
}

.expcedted-downtime {
  display: inline-flex;
}
.system-mile-unit {
  padding-right: 10px !important;
}
.system-impacted-drop {
  /*width: 320px;*/
  width:100%;
}
.downtime-drop{
  width: 290px;
}
.label-frequency{
  /*margin-left: 40px !important;
  padding-left:16px !important;*/
}
#frequency{
   /*padding-left:16px !important;*/
}
.system-cancel {
  margin-top: 10px !important;
  margin-right: 40px !important;
  width: 120px !important;
  border-radius: 20px !important;
  border: 1px solid gray !important;
}
.system-save {
  margin-top: 10px !important;
  margin-left: 40px !important;
  /*margin-left: 80px !important;*/
  
  width: 120px !important;
  border-radius: 20px !important;
  color: white !important;
  background-color: black !important;
}
.system-button{
  margin-top: 15px;
}
.event-view-date {
  display: flex;
}
.display-at {
  padding: 20px 20px 10px 10px;
}
.date-picker {
  width: 150px;
}
.time-picker {
  width: 150px !important;
}
#date-picker-dialog-label {
  /*padding-left: 20px;*/
  font-size: 14px;
}
#time-picker-label {
  padding-left: 20px;
  font-size: 14px;
}
.ouatge-date-picker .MuiIconButton-root{
  padding:0px;
}
.date-picker .MuiIconButton-root{
  padding:0px;
}
.evnet-checkbox {
  display: flex;
}

.back-button {
  margin-top: 10px !important;
  margin-right: 40px !important;
  width: 120px !important;
  border-radius: 20px !important;
  border: 1px solid gray !important;
}

.add-dates {
  color: blue;
  cursor: pointer;
  text-align: center;
}
.add-dates-dis {
  color: gray;
  cursor: none;
  text-align: center;
}

.outage-details {
  width: 93% !important;
  border: none;
  border-bottom: 1px solid #000;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  padding-left: 16px;
}
.label-outage{
  /*padding-left:16px !important;*/
}
#tags-standard-label{
  /*padding-left: 16px;*/
}
.MuiFormLabel-root.Mui-focused{
  color:grey !important;
  /*padding-left:16px;*/
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}
#standard-basic{
  padding-left: 0px;
}
.padding-outage{
  padding-left: 16px;
}

.ele-lb {
  font-size: 14px;
  padding-left: 20px;
}

div#outageType {
  font-size: 16px;
  /*padding-left: 16px;*/
}

#ouatge-date-picker {
  font-size: 18px;
  margin-top: 16px !important;
  margin-bottom: 8px !important;
}
.filterCancel {
  border: 0.5px solid rgba(20, 20, 20, 0.3) !important;
  box-sizing: border-box !important;
  border-radius: 20px !important;
  height: 32px !important;
}

.NewOutageEvent {
  font-size: 20px;
  font-weight: normal;
  margin: 0.5em 1em 0.5em 0em;
  color: rgba(0, 0, 0, 0.87);
}
.event-save{
  margin-left:50px !important;
}
.outage_filter{
  margin-top:15px !important;
}
.OutageTypeLabel{
 /* padding-left: 20px !important;*/
  font-size: 14px !important;
}
.FilterPopup{
  margin: 10px 14px 20px 14px !important;
}
.FilterButton{
  margin-top:10px;
}
.FilterLabel{
  font-family: Roboto, Helvetica, Arial, sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  letter-spacing: 0.15008px !important;
  line-height: 24px !important;
  /*padding-left: 16px !important;*/
}
.padding-left{
  padding-left:12px !important;
}
.char-limit {
  float: right;
  line-height: 0;
}

.MuiTableCell-root{
  padding: 4px !important;
}
.reset-frequency{
  display:inline-flex;
  width:100%;
}
.frequency-refresh {
  /*margin: 0px 10px !important;*/
  margin: 0px 10px 10px 0px  !important;
  cursor: pointer;
}
.frequency-refresh-icon{
  width: 10%;
  border-bottom: 1px solid rgba(0, 0, 0,0.42);
  margin: 20px 0 0 0;

}
.frequency{
  width: 90%;
}
.btn-status{
  text-align: left !important;
}
.btn-status .MuiButton-label{
  display: block;
}
.btn-status.Mui-disabled.Mui-disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    border: none;
    background-color: white !important;
}
.error-msg{
  color: #B90000;
  width: 286px;
height: 12px;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 12px;
margin-top: -1px;
}