.grid-item {
  width: 100%;
  margin: 5px 0px !important;
}

.get-pricing {
  margin: 10px auto !important;
}

.MuiBox-root {
  padding: 0 !important;
}

.MuiTab-root {
  min-width: 50% !important;
}

.offline-tabs {
  padding: 10px 0px 10px 0px;
}
