.product-details-img {
  display: inline-block;
  vertical-align: top;
  /* padding-top: 15px; */
}

.product-detail-style {
  display: inline-block;
  padding-left: 0px;
  line-height: .35 !important;
}

.product-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor:pointer
}

.MuiTableCell-head{
  font-weight:600 !important;
  font-size: 15px;
  color: #5e5e5e !important;
}