.rating-card {
    text-align: center;
    width: 300px;
    padding: 5px;
    margin: 5px;
}

.rating-plan {
    font-size: 20px;
    font-weight: 800;
}

.rating-price {
    font-weight: bold;
}

.rating-term-mileage,
.rating-deductable {
    font-size: 14px;
}