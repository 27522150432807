.offline-rating-container {
  padding: 10px;
  margin-bottom: 120px;
}

.rating-title {
  font-size: 20px;
  margin-left: 30px;
}

.vehicle-detail {
  padding-left: 10px !important;
}

.vehicle-heading {
  display: inline-flex;
}

.vehicle-radio {
  display: inline-flex;
}

.label-style {
  line-height: 40px;
  padding-right: 50px;
}

.vehicle-input {
  display: flex;
  flex-direction: row;
}

.select-class {
  width: 100% !important;
}

.expand-icon {
  padding-top: 10px;
  padding-right: 10px;
}

.MuiAccordionDetails-root {
  display: block !important;
}

.offline-button {
  padding-top: 30px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white !important;
}

.get-pricing {
  line-height: 18px !important;
  text-transform: capitalize !important;
  width: 120px !important;
  border-radius: 20px !important;
  font-size: 15px !important;
}

.enable-button {
  color: white !important;
  background-color: black !important;
}

.disable-button {
  color: black !important;
  background-color: #F1F1F1 !important;
}
.vin-tab, .manual-tab {
  min-width: 150px !important;
  text-align: center;
}
#vehicle-detailg-container #date-picker-dialog-label, #time-picker-label {
  padding-left: 0px;
  font-size: 16px;
}

#vehicle-detailg-container .MuiIconButton-root{
  padding:0;
}
#offline-rating-container .MuiGrid-grid-xs-3 {
  max-width: 30%;
  flex-basis: 30%;
}
#offline-rating-container .MuiGrid-grid-xs-9 {
  max-width: 70%;
  flex-basis: 70%;
}
#vehicle-detailg-container .MuiTabs-flexContainer{
  border-bottom: 1px solid #BCC9D6;
}
.reset{
  color:#0066CC;
  margin-right: 5px !important;
}
.quickquote{
  color:#13223C;
  font-size:18px;
  margin:0px;
}
#vehicle-detailg-container .MuiInputBase-input {
  font-size: 16px;
  color: #68758B;
}
#vehicle-listingg-container, #vehicle-listingg-container .MuiInputBase-input{
  color:#3D515A;
}
#offline-rating-container .MuiInput-underline.Mui-disabled:before {
  border-bottom-style: solid;
}

#vehicle-detailg-container .MuiFormControlLabel-root{
  margin-left:0;
}
.MuiTab-textColorPrimary {
  color: #3C4D6A;
  font-size:16px;
}
#vehicle-detailg-container .MuiGrid-spacing-xs-2 {
 margin: 0px;
}
#full-width-tabpanel-0 .MuiGrid-spacing-xs-2 .MuiGrid-item {
  padding: 8px 0px 8px 8px;
}
