.system-grey {
    padding-top: 6px;
    background-color: rgb(48,51,64);
    border-top-right-radius: 10px 10px;
    border-bottom-right-radius: 10px 10px;
    width: 60px;    
    margin-right: 10px;
    height: 40px;
    margin-top: 8px;
}

.system-grey-icon {
    float: right !important;
    margin-right: 8px;
    height: 40px;
}

.offline-syste-status {
    /*width: 410px !important;*/
    width: 25%;
}

.offline-rating-messege {
    border: 1px solid rgb(48,51,64);
    margin: 15px !important;
    padding: 5px;
    display: inline-flex;
    border-radius: 5px;
    width:80%;
    line-height: 1 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
}

.offline-rating-btn {
    margin-left: 0%;
    white-space: nowrap;
    margin-top: 2px;
}

.offline-btn {
    padding: 10px !important;
    border-radius: 20px !important;
    background-color: black !important;
    color: white !important;
    width: 150px !important;
    height: 30px !important;
    font-size: 16px !important;
    margin: 10px !important;
}

.offline-message {
    width: 80%;
    line-height: 2;
    margin: 5px;
}
.outage-detected {
    border: 1px solid #B90000;
}
