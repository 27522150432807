.login-style {
    background-color:white;
    width:400px;
    height:525px;
    border-radius:8px; 
}

.logo-style{
    padding-top: 0px !important;
}

.digital-hub-name {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    margin: 10px;
}

.login-form {
    padding-left: 30px;
}


.login-label {
    padding-left: 10px !important;
}

.form-rember-me {
    display: flex;
}

.signin-button {
    line-height: 20px !important;
    text-transform: uppercase !important;
    width: 150px !important;
    border-radius: 20px !important;
}

.need-sign-in {
    color:#2046F5;
    text-align: center;
}

.sign-in-link {
    color:#2046F5;
    font-weight: 600;
    text-align: center;  
    cursor: pointer;
}

.color-style {
   color:  rgba#3d5151;
}